
import { Options, Vue } from 'vue-class-component';
import { apiListAbnormalGetList, 
         apiListAbnormalGetListTotalNum } from '@/services/tpsApi/tpsApiListAbnormalTx';
import { apiBrandGetListName } from '@/services/tpsApi/tpsApiBrand';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '@/services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { IListAbnormalList } from '@/services/tpsApi/models/IListAbnormal';
import Table from '@/components/Table.vue';
import Pagination from '@/components/Pagination.vue';
import DatepickerLite from "vue3-datepicker-lite";

@Options({
  components: {
    DatepickerLite,
    Table,
    Pagination
  },
})
export default class Transaction extends Vue {
    TABLE_HEAD: {} = {
        time: "發生時間",
        brand_id: "品牌名稱",
        plate_no: "車號",
        reader_sn: "卡機號碼",
        reader_id: "卡機內碼",
        error_msg: "異常訊息"
    };

    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    filter_startdate: string = "";
    filter_enddate: string = "";
    filter_brand_id: string = "";
    formVisible: boolean = false;
    listAbnormalList: IListAbnormalList = [];
    pageNo: {} = PAGENO;

    //Date picker setting
    startDate = {
        id: "startDate",
        class: "md-input myDateInput md-datepicker",
        value: "選擇日期",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.filter_startdate = value;
        }
    }

    endDate = {
        id: "endDate",
        class: "md-input myDateInput md-datepicker",
        value: "選擇日期",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.filter_enddate = value;
        }
    }

    brandSelectOptions: {} ={};

    async mounted() {
        this.refreshTabel();
        this.initPagination();
        this.brandSelectOptions = await apiBrandGetListName();
    }

    async initPagination(pData?: {
        startdate?: string,
        enddate?: string,
        brand_id?: string }) {
        let data = {};
        if(pData && pData.startdate) data["startdate"] = pData.startdate;
        if(pData && pData.enddate) data["enddate"] = pData.enddate;
        if(pData && pData.brand_id) data["brand_id"] = pData.brand_id;

        this.pageTotalNum = await apiListAbnormalGetListTotalNum(data);
    }
    
    async refreshTabel(pData?: {
        limit?: number, 
        page?: number,
        startdate?: string,
        enddate?: string,
        brand_id?: string }) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;

        let data = {limit: this.currentLimit, page:this.currentPage};
        if(pData && pData.startdate) data["startdate"] = pData.startdate;
        if(pData && pData.enddate) data["enddate"] = pData.enddate;
        if(pData && pData.brand_id) data["brand_id"] = pData.brand_id;

        this.listAbnormalList = await apiListAbnormalGetList(data);
    }  

    doSearch() {
        let data = {limit: DEFAULT_ITEMS_PER_PAGE,
                    page: DEFAULT_START_PAGE,
                    startdate: this.filter_startdate,
                    enddate: this.filter_enddate,
                    brand_id: this.filter_brand_id }
        this.initPagination(data);
        this.refreshTabel(data);
    }
}

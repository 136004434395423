import tpsApiInstance from "./tpsApiBase";
import {
    IListAbnormalGetListTotalNumRequestParams,
    IListAbnormalGetListRequestParams,
    IListAbnormalList
} from './models/IListAbnormal';

const LIST_ABNORMAL = "/listCriticalReport";

export const apiListAbnormalGetListTotalNum = async(
    pData?: IListAbnormalGetListTotalNumRequestParams): Promise<number> => {
        let query: string = "?count=total";
        if(pData && pData.startdate) query+= `&startdate=${pData.startdate}`;
        if(pData && pData.enddate) query+= `&enddate=${pData.enddate}`;
        if(pData && pData.brand_id) query+= `&brand_id=${pData.brand_id}`;
        
        const res = await tpsApiInstance.get(`${LIST_ABNORMAL}${query}`);
        console.log("total:",res?.data[0]?.total);
        return res?.data[0]?.total;
};

export const apiListAbnormalGetList = async(
    pData: IListAbnormalGetListRequestParams): Promise<IListAbnormalList> => {
        let query: string = "?";
        if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
        if(pData && pData.startdate) query+= `&startdate=${pData.startdate}`;
        if(pData && pData.enddate) query+= `&enddate=${pData.enddate}`;
        if(pData && pData.brand_id) query+= `&brand_id=${pData.brand_id}`;
        
        console.log(query);
        const res = await tpsApiInstance.get(`${LIST_ABNORMAL}${query}`);
        return res?.data as IListAbnormalList;
};
